import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "kegiatan";
const modelPath = "/aktivitas/kegiatan-pspd/";

class KegiatanPSPD extends ModelBase {
  constructor() {
    let model = {
      id: null,
      preceptor: null,
      lokasi: null,
      tanggal: null,
      metode: null,
      kegiatan: "",
      jenis: "",
      foto: null,
      catatan: ""
    };
    let requiredFields = ["tanggal", "preceptor", "kegiatan", "jenis", "metode"];
    super(modelName, model, requiredFields, ["foto", "lokasi", "catatan"], modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    if (tdata.lokasi) {
      tdata.lokasi = tdata.lokasi.id;
    }
    tdata.preceptor = tdata.preceptor.id;
    tdata.metode = tdata.metode.id;
    return tdata;
  }

  getPayload() {
    let data = super.getPayload();
    if (data.jenis == "Online") {
      data.lokasi = null;
    }
    return data;
  }

}

export default KegiatanPSPD;
